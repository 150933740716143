@if (!hiddenKeyMap['quick-filter']) {
  <div class="grid md:grid-cols-3 gap-4 my-4">
    @if (
      (filter['user-id'] &&
        filter['user-id'].length > 0 &&
        !hiddenKeyMap['user-id']) ||
      (filter['agency-id'] &&
        filter['agency-id'].length > 0 &&
        !hiddenKeyMap['agency-id']) ||
      (filter['agent-id'] &&
        filter['agent-id'].length > 0 &&
        !hiddenKeyMap['agent-id'])
    ) {
      <div>
        @if (
          filter['user-id'] &&
          filter['user-id'].length > 0 &&
          !hiddenKeyMap['user-id']
        ) {
          <div>
            <label class="label">
              <span class="label-text">View as</span>
            </label>
            <div class="flex flex-wrap fit">
              @for (i of filter['user-id']; track i; let j = $index) {
                <button
                  class="btn btn-sm mb-0 max-w-full h-auto btn-outline times pl-0 text-left"
                  (click)="removeFromFilter('user-id', i.id)"
                >
                  <app-user-card [small]="true" [userId]="i.id"></app-user-card>
                  <!-- <img
                *ngIf="agency.profileImage"
                [src]="agency.profileImage"
                class="rounded-full w-12 h-12 object-cover -ml-4" />
              <div>
                @if (agency.firstName) {
                  <span>{{ agency.firstName ?? '' }}</span>
                } @else if (agency.name) {
                  <span>{{ agency.name ?? '' }}</span>
                } @else {
                  <span>{{ i.id ?? '' }}</span>
                }
              </div> -->
                  <fa-icon [icon]="faTimes"></fa-icon>
                </button>
              }
            </div>
          </div>
        }
        @if (
          filter['agency-id'] &&
          filter['agency-id'].length > 0 &&
          !hiddenKeyMap['agency-id']
        ) {
          <div>
            <label class="label">
              <span class="label-text">Selected Agencies</span>
            </label>
            <div class="flex flex-wrap fit">
              @for (i of filter['agency-id']; track i; let j = $index) {
                @if (agencyMap[i.id]; as agency) {
                  <button
                    class="btn m-1 max-w-full h-auto btn-outline times"
                    (click)="removeFromFilter('agency-id', i.id)"
                  >
                    @if (agency.profileImage) {
                      <img
                        [src]="agency.profileImage"
                        class="rounded-full w-12 h-12 object-cover -ml-4"
                      />
                    }
                    <div>
                      <span>{{ agency.name ?? '' }}</span>
                    </div>
                    <fa-icon [icon]="faTimes"></fa-icon>
                  </button>
                }
              }
            </div>
          </div>
        }
        @if (
          filter['agent-id'] &&
          filter['agent-id'].length > 0 &&
          !hiddenKeyMap['agent-id']
        ) {
          <div>
            <label class="label">
              <span class="label-text">Selected Agents</span>
            </label>
            <div class="flex flex-wrap fit">
              @for (i of filter['agent-id']; track i; let j = $index) {
                @if (agentMap[i.id]; as agent) {
                  <button
                    class="btn m-1 max-w-full h-auto btn-outline times"
                    (click)="removeFromFilter('agent-id', i.id)"
                  >
                    @if (agent.profileImage) {
                      <img
                        [src]="agent.profileImage"
                        class="rounded-full w-12 h-12 object-cover -ml-4"
                      />
                    }
                    <div>
                      <span>
                        {{ agent.firstName ?? '' }}
                        {{ agent.lastName ?? '' }}
                      </span>
                    </div>
                    <fa-icon [icon]="faTimes"></fa-icon>
                  </button>
                }
              }
            </div>
          </div>
        }
      </div>
    }
    <div class="md:col-span-2">
      <!-- <app-commission-statistics-filter-type-selector
        [filter]="filter"
        (filterChanged)="onFilterChange('agency-filter-type', $event)"
      ></app-commission-statistics-filter-type-selector> -->

      <div>
        <label class="label">
          <span class="label-text">Filter by Payout Type</span>
        </label>
        <app-tags
          bindLabel="title"
          bindValue=""
          [items]="payoutTypeListFilter"
          [small]="true"
          [value]="filter['payoutType']"
          (valueChange)="onFilterChange('payoutType', $event)"
        ></app-tags>
      </div>
    </div>
  </div>
}

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { takeWhile } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserCardComponent } from '../../ui-components/user-card/user-card.component';
import { payoutTypeListFilterJson } from 'src/app/shared/data/payoutType';
import { TagsComponent } from '../../ui-components/tags/tags.component';

@Component({
  selector: 'app-commission-statistics-quick-filter',
  templateUrl: './commission-statistics-quick-filter.component.html',
  styleUrls: ['./commission-statistics-quick-filter.component.scss'],
  standalone: true,
  imports: [FaIconComponent, TagsComponent, UserCardComponent],
})
export class CommissionStatisticsQuickFilterComponent implements OnInit {
  private authService = inject(AuthService);
  private cdr = inject(ChangeDetectorRef);

  faTimes = faTimes;

  @Input() data: any;
  @Input() filter: any;
  @Input() hiddenKeyMap: any = {};

  @Output() filterChanged = new EventEmitter<any>();

  agencyMap: any = {};
  agentMap: any = {};
  userMap: any = {};

  payoutTypeListFilter = payoutTypeListFilterJson;

  ngOnInit() {
    this.authService.currentUser$
      .pipe(takeWhile((x) => !x, true))
      .subscribe((user: any) => {
        if (user) {
          this.userMap[user.id] = user;
        }
      });

    this.data?.data?.['agency-id']?.forEach((x: any) => {
      this.agencyMap[x.id] = x;
      this.userMap[x.id] = x;
    });
    this.data?.data?.['agent-id']?.forEach((x: any) => {
      this.agentMap[x.id] = x;
      this.userMap[x.id] = x;
    });
  }

  removeFromFilter(tagName: string, id: string) {
    this.filter[tagName] = this.filter[tagName].filter((x: any) => x.id !== id);
    this.filterChanged.emit();
  }

  onFilterChange(tagName: string, event: any, forceScroll = false) {
    this.filterChanged.emit({ forceScroll });
    this.cdr.detectChanges();
  }
}
